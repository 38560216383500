<script>
    export let profile;
    export let isListMode;
    export let isListManageable;
</script>

<div class="mb-2 rounded-lg group bg-gray-800 text-white rounded-lg overflow-clip" data-memberuserid={profile.id}>
    <a href={"/" + profile.username} class="relative block ">
        <div class="h-24  bg-cover bg-center transform blur-sm scale-[1.1]" style="background-image: url({profile.cover});"></div>
        <div class="absolute inset-0 rounded-lg">
            <div class="absolute inset-0 z-0">
                <div class="h-full w-full dark:backdrop-brightness-[20%] dark:group-hover:backdrop-brightness-[25%] backdrop-brightness-[45%]  group-hover:backdrop-brightness-[50%] transition-all duration-200"></div>
            </div>
            <div class="relative flex h-full w-full justify-center items-center p-3">
                <div class="z-10 mr-3">
                    <img src={profile.avatar} class="h-16 w-16 rounded-full " alt="" />
                </div>
                <div class="z-10 flex-grow truncate">
                    <div class="flex justify-between items-center">
                        <div class="truncate">
                            <div class="flex items-center text-lg font-bold text-white">
                                {profile.name}
                                {#if profile.email_verified_at && profile.birthdate && profile.verification && profile.verification.status === 'verified'}
                                    <span class="ml-1" title="Verified user">
                                        <!-- Add verified icon here -->
                                    </span>
                                {/if}
                            </div>
                            <div class="text-sm font-light text-white">@{profile.username}</div>
                        </div>
                        {#if isListMode && isListManageable}
                            <button
                                class="h-6 w-6 rounded bg-red-500"
                                title="Delete"
                                on:click={() => Lists.showListMemberRemoveModal(profile.id)}
                            >
                                <!-- Add delete icon here -->
                            </button>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>
